/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import CustomProps from 'common/prop-types';
import MJEBanner from 'common/components/mjeBanner/MJEBanner';
import { LoadingSpinner } from 'common/components';
import * as actions from 'actions';

import Uploader from './components/Uploader';
import ResourcesButton from './components/ResourcesButton';
import MyMJEDashboard from './components/MyMJEDashboard';
import styles from './LandingPage.scss';
import { clearSession } from '../../utils/storage';

export class LandingPageComponent extends React.Component {
  static propTypes = {
    username: PropTypes.string,
    loading: PropTypes.bool,
    searching: PropTypes.bool,
    copiedWorkbook: PropTypes.shape({
      workbookID: PropTypes.string,
    }),

    // workbook: CustomProps.Workbook,
    searchItems: PropTypes.arrayOf(CustomProps.MJESearchItem),
    downloadTemplateUrl: PropTypes.string,
    templateDownloading: PropTypes.bool,
    downloadWorkbookUrl: PropTypes.string,

    // Redux Functions
    uploadFile: PropTypes.func.isRequired,
    uploadFileStepByStep: PropTypes.func.isRequired,

    downloadTemplate: PropTypes.func,
    downloadTemplateReset: PropTypes.func,
    myMJEDashboardSearch: PropTypes.func,
    clearCopiedFile: PropTypes.func,
    copyFile: PropTypes.func,
    deleteWorkbook: PropTypes.func,
    downloadWorkbook: PropTypes.func,
    downloadWorkbookReset: PropTypes.func,
    expressUploadPostFile: PropTypes.func,
    downloadInvoice: PropTypes.func,
    downloadInvoiceResponse: PropTypes.shape({
      fileBytes: PropTypes.object,
      fileName: PropTypes.string,
    }),
    downloadInvoiceReset: PropTypes.func,
    // React Router Props
    // location: CustomProps.location(),
    // match: CustomProps.match(),
    history: CustomProps.history(),
  };

  static defaultProps = {
    username: null,
    copiedWorkbook: null,
    loading: true,
    searching: false,
    searchItems: [],
    downloadTemplateUrl: null,
    templateDownloading: false,
    downloadWorkbookUrl: null,
    downloadInvoiceResponse: null,

    downloadTemplate: () => { },
    downloadTemplateReset: () => { },
    myMJEDashboardSearch: () => { },
    clearCopiedFile: () => { },
    copyFile: () => { },
    deleteWorkbook: () => { },
    downloadWorkbook: () => { },
    downloadInvoice: () => { },
    downloadWorkbookReset: () => { },
    expressUploadPostFile: () => { },
    downloadInvoiceReset: () => { },
    history: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      spinnerText: 'Loading...',
      invoiceDownloadUrl: null,
      invoiceFileName: null,
    };

    this.onExpressUploadFile = this.onExpressUploadFile.bind(this);
    this.onUploadFileStepByStep = this.onUploadFileStepByStep.bind(this);
    this.handleResourcesButtonClick = this.handleResourcesButtonClick.bind(this);
    this.handleSearchGo = this.handleSearchGo.bind(this);
    this.handleNewRedirect = this.handleNewRedirect.bind(this);
    this.handleCopyFileRequest = this.handleCopyFileRequest.bind(this);
    this.handleDeleteWorkbookRequest = this.handleDeleteWorkbookRequest.bind(this);
    this.handleDownloadWorkbookRequest = this.handleDownloadWorkbookRequest.bind(this);
    this.handleDownloadInvoiceRequest = this.handleDownloadInvoiceRequest.bind(this);
    this.triggerClientDownload = this.triggerClientDownload.bind(this);
  }

  componentDidMount() { }

  componentDidUpdate() {
    const {
      downloadTemplateUrl, copiedWorkbook, history, clearCopiedFile,
      downloadWorkbookUrl, downloadWorkbookReset,
      downloadInvoiceResponse, downloadInvoiceReset,
    } = this.props;

    if (downloadTemplateUrl !== null) {
      window.open(downloadTemplateUrl);
      const { downloadTemplateReset } = this.props;
      downloadTemplateReset();
    }
    if (copiedWorkbook != null) {
      if (window.location.href.includes('/MJE1_1')) {
        history.push(`/MJE1_1/Validation/workbook/${copiedWorkbook.workbookID}`);
      } else {
        history.push(`/MJE/Validation/workbook/${copiedWorkbook.workbookID}`);
      }
      clearCopiedFile();
    }
    if (downloadWorkbookUrl !== null) {
      window.open(downloadWorkbookUrl);
      downloadWorkbookReset();
    }
    if (downloadInvoiceResponse != null) {
      const blob = new Blob([downloadInvoiceResponse.fileBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      this.triggerClientDownload(url, downloadInvoiceResponse.fileName);
      downloadInvoiceReset();
    }
  }

  onExpressUploadFile({ file /* , fileList */ }) {
    const { uploadFile, expressUploadPostFile, history } = this.props;
    this.setState(() => ({ spinnerText: 'Uploading file...' }));
    uploadFile(file)
      .then(({ workbook_id, valid }) => {
        clearSession();

        const requestType = 'workbook';
        const requestId = workbook_id;

        if (window.location.href.includes('/MJE1_1')) {
          if (valid) {
            return expressUploadPostFile(workbook_id).then(() => {
              history.push('/MJE1_1/PostSuccess');
            });
          }
          // If file has errors, navigate to Validation UI
          history.push(`/MJE1_1/Validation/${requestType}/${requestId}`);
          return null;
        } else {
          if (valid) {
            return expressUploadPostFile(workbook_id).then(() => {
              history.push('/MJE/PostSuccess');
            });
          }
          // If file has errors, navigate to Validation UI
          history.push(`/MJE/Validation/${requestType}/${requestId}`);
          return null;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onUploadFileStepByStep({ file /* , fileList */ }) {
    const { uploadFileStepByStep, history } = this.props;
    this.setState(() => ({ spinnerText: 'Uploading file...' }));
    uploadFileStepByStep(file).then(({ workbook_id }) => {
      clearSession();

      // Navigate to Validation UI
      const requestType = 'workbook';
      const requestId = workbook_id;

      if (window.location.href.includes('/MJE1_1')) {
        history.push(`/MJE1_1/Validation/${requestType}/${requestId}`);
      } else {
        history.push(`/MJE/Validation/${requestType}/${requestId}`);
      }
    });
  }

  onMyMJEDashboardSearch(searchPayload) {
    const { myMJEDashboardSearch } = this.props;
    myMJEDashboardSearch(searchPayload);
  }

  triggerClientDownload(url, fileName) {
    this.setState(() => ({ invoiceFileName: fileName, invoiceDownloadUrl: url }));
    console.log(`Set state to: ${url} and ${fileName}`);
    setTimeout(() => {
      const downloadInvoiceLink = document.getElementById('downloadInvoiceLink');
      downloadInvoiceLink.click();
    }, 100);
  }

  // eslint-disable-next-line class-methods-use-this
  handleResourcesButtonClick(buttonId) {
    // TODO: call corresponding API
    const { history } = this.props;
    if (buttonId === 'reports') {
      clearSession();
      history.push('/MJE/Reports');
    }

    if (buttonId === 'template') {
      const { downloadTemplate } = this.props;
      downloadTemplate();
    }

    if (buttonId === 'approvals') {
      const approvalPageURL = process.env.APPROVALS_PAGE_URL;
      window.open(approvalPageURL);
    }

    if (buttonId === 'readme') {
      window.open('https://w.amazon.com/bin/view/Manual_Journal_Entry_1.0_Tool');
    }
  }

  handleSearchGo(parameters) {
    const { myMJEDashboardSearch } = this.props;
    myMJEDashboardSearch(parameters);
  }

  handleNewRedirect(internalRoute) {
    clearSession();

    const { history } = this.props;
    history.push(internalRoute);
  }

  handleCopyFileRequest(payload) {
    const { copyFile } = this.props;
    this.setState(() => ({ spinnerText: 'Copying file...' }));
    copyFile(payload);
  }

  handleDeleteWorkbookRequest(payload) {
    const { deleteWorkbook } = this.props;
    this.setState(() => ({ spinnerText: 'Deleting workbook...' }));
    deleteWorkbook(payload);
  }

  handleDownloadWorkbookRequest(workbookId) {
    const requestType = 'workbook';
    const { downloadWorkbook } = this.props;
    this.setState(() => ({ spinnerText: 'Downloading workbook...' }));
    downloadWorkbook({ requestType, requestId: workbookId });
  }

  handleDownloadInvoiceRequest(payload) {
    const { downloadInvoice } = this.props;
    this.setState(() => ({ spinnerText: 'Downloading invoice...' }));
    downloadInvoice(payload);
  }

  render() {
    const {
      loading, username, searchItems, searching, templateDownloading,
    } = this.props;
    const { spinnerText, invoiceFileName, invoiceDownloadUrl } = this.state;

    const bannerText = `Welcome ${username}@ to the Amazon Intercompany Manual Journal Entry Tool`;

    return (
      <div className={styles.wrapper}>
        <MJEBanner bannerText={bannerText} />
        <LoadingSpinner loading={loading} text={spinnerText} />
        <br />
        {/* Resources toolbar */}
        <div className="awsui-util-container">
          <div className="awsui-util-container-header">
            <h2 className="awsui-util-font-size-3">MJE Resources</h2>
          </div>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              <div className={styles.centerAlignContent}>
                <ResourcesButton
                  value={templateDownloading ? 'Downloading...' : 'New Template'}
                  tooltipText="Download the latest MJE template"
                  iconType={templateDownloading ? null : 'download'}
                  onSubmit={() => this.handleResourcesButtonClick('template')}
                  disabled={templateDownloading}
                  loading={templateDownloading}
                />
              </div>
              <div className={styles.centerAlignContent}>
                <ResourcesButton
                  value="MJE Reports"
                  onSubmit={() => this.handleResourcesButtonClick('reports')}
                  tooltipText="Go to My Reports Dashboard"
                  iconType="folder-open"
                  disabled={false}
                />
              </div>
              <div className={styles.centerAlignContent}>
                <ResourcesButton
                  onSubmit={() => this.handleResourcesButtonClick('approvals')}
                  value="My Approvals"
                  tooltipText="Go to My Approvals Dashboard"
                  iconType="status-positive"
                  disabled={false}
                />
              </div>
              <div className={styles.centerAlignContent}>
                <ResourcesButton
                  onSubmit={() => this.handleResourcesButtonClick('readme')}
                  value="Help"
                  tooltipText="Troubleshoot Tool Or Submit Ticket"
                  iconType="status-info"
                  disabled={false}
                />
              </div>
            </div>
          </ColumnLayout>
        </div>
        <br />
        <div className={styles.divider} />
        <br />

        {/* Upload section */}
        <div className="awsui-util-container">
          <div className="awsui-util-container-header">
            <h2 className="awsui-util-font-size-3">MJE Upload</h2>
          </div>
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <div>
                <Uploader
                  header="!!!Express Upload!!!"
                  text="or drag and drop it here"
                  buttonText="Select Template"
                  buttonTooltip="Select Your MJE to Upload"
                  onFileChosen={this.onExpressUploadFile}
                />
              </div>
              <div>
                <Uploader
                  header="Step By Step"
                  text="or drag and drop it here"
                  buttonText="Select Template"
                  buttonTooltip="Select Your MJE to Upload"
                  onFileChosen={this.onUploadFileStepByStep}
                />
              </div>
            </div>
          </ColumnLayout>
        </div>
        <br />
        <div className={styles.divider} />
        <br />
        {invoiceFileName && <a id="downloadInvoiceLink" className="hidden" download={invoiceFileName} href={invoiceDownloadUrl}>Download Invoice</a>}
        {/* My MJE dashboard */}

        <MyMJEDashboard
          items={searchItems || []}
          isSearching={searching}
          username={username}
          onNewSearch={this.handleSearchGo}
          onNewRedirectRequest={this.handleNewRedirect}
          onCopyFileRequest={this.handleCopyFileRequest}
          onDeleteWorkbookRequest={this.handleDeleteWorkbookRequest}
          onDownloadWorkbookRequest={this.handleDownloadWorkbookRequest}
          onDownloadInvoiceRequest={this.handleDownloadInvoiceRequest}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    loading,
    searching,
    searchItems,
    workbook,
    downloadTemplateUrl,
    templateDownloading,
    copiedWorkbook,
    downloadWorkbookUrl,
    downloadInvoiceResponse,
  } = state.landingPage;
  const { username } = state.auth;
  return {
    username,
    loading,
    searching,
    workbook,
    searchItems,
    downloadTemplateUrl,
    templateDownloading,
    copiedWorkbook,
    downloadWorkbookUrl,
    downloadInvoiceResponse,
  };
};

const mapDispatchToProps = {
  uploadFile: actions.uploadFile,
  uploadFileStepByStep: actions.uploadFileStepByStep,
  myMJEDashboardSearch: actions.myMJEDashboardSearch,
  downloadTemplate: actions.downloadTemplate,
  downloadTemplateReset: actions.downloadTemplateReset,
  clearCopiedFile: actions.resetCopiedFile,
  copyFile: actions.copyFile,
  deleteWorkbook: actions.deleteWorkbook,
  downloadWorkbook: actions.downloadWorkbook,
  downloadWorkbookReset: actions.downloadWorkbookReset,
  expressUploadPostFile: actions.expressUploadPostFile,
  downloadInvoice: actions.downloadInvoice,
  downloadInvoiceReset: actions.downloadInvoiceReset,
};

const LandingPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingPageComponent);

export default withRouter(LandingPageContainer);
