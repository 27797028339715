import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  loading: false,
  error: null,
  workbookName: '',
  statistics: {},
  reversal: new Map(),
  reversalAllJournals: {
    journalName: 'All Journals',
    methods: [],
    periods: [],
    selected: false,
    selectedMethod: null,
    selectedPeriod: null,
    reversed: false,
    disabled: false,
  },
  dataGridColumns: [],
  dataGrid: [],
  dataGridChanges: [],
  postingSummary: {},
  isPosting: false,
  needsRevalidate: false,
  needsTaxGeneration: true,
  workbookDeleted: false,

  // This is used to capture the original values so that Undo button on Reversal can work
  originalModels: {},
  successfulSaveDate: null,
  isSaving: false,
  downloadWorkbookUrl: null,
};

export default createReducer(initialState, {
  [CONST.VALIDATION_UI_EDIT_REVERSAL]: (state, action) => {
    const { reversal, reversalAllJournals } = state;
    // When "All Journals" is selected/deselected
    if (action.batchName == null && action.index === -1) {
      reversal.forEach((values, keys) => {
        values.forEach((transaction) => {
          if (action.selected) {
            transaction.disabled = true;
            transaction.selected = false;
            transaction.selectedMethod = null;
            transaction.selectedPeriod = null;
          } else {
            transaction.disabled = false;
          }          
        });
      });
      return {
        ...state,
        reversal: new Map(reversal),
        reversalAllJournals: {
          ...reversalAllJournals,
          selected: action.selected,
          selectedMethod: action.selectedMethod,
          selectedPeriod: action.selectedPeriod,
        },
      };
    }
    const updatedTransactions = reversal.get(action.batchName).map((journal, index) => {
      if (index === action.index) {
        return {
          ...journal,
          selected: action.selected,
          selectedPeriod: action.selectedPeriod,
          selectedMethod: action.selectedMethod,
          disabled: false,
        };
      }
      return journal;
    });
    return {
      ...state,
      reversal: new Map(reversal.set(action.batchName, updatedTransactions)),
    };
  },

  [CONST.VALIDATION_UI_EDIT_DATA_GRID]: (state, action) => ({
    ...state,
    dataGrid: state.dataGrid.map((item, index) => {
      if (index === action.index) {
        let newItem = {
          ...item,
          [action.column]: action.value,
          last_update_user: action.last_update_user,
          last_update_date: action.last_update_date,
        };

        if (item.errorsEdited && item.errorsEdited[action.column] === false) {
          newItem = {
            ...newItem,
            errorsEdited: {
              ...item.errorsEdited,
              [action.column]: true,
            },
          };
        }

        return newItem;
      }
      return item;
    }),
    dataGridChanges:
      state.dataGridChanges.indexOf(state.dataGrid[action.index].seq_id) === -1
        ? [...state.dataGridChanges, state.dataGrid[action.index].seq_id]
        : state.dataGridChanges,
    needsRevalidate: true,
    needsTaxGeneration: true,
    indirectTaxSummary: [],
  }),

  [CONST.VALIDATION_UI_EDIT_DATA_GRID_BATCH]: (state, action) => ({
    ...state,
    dataGrid: state.dataGrid.map((item, index) => {
      if (action.indexes.includes(index)) {
        const newItem = {
          ...item,
          reversal_period: action.reversal_period,
          reversal_method: action.reversal_method,
          last_update_user: action.last_update_user,
          last_update_date: action.last_update_date,
        };
        return newItem;
      }
      return item;
    }),
    dataGridChanges:
      state.dataGridChanges.concat(action.indexes.map(index => state.dataGrid[index].seq_id).filter(seqId => seqId >= 0)),
    needsRevalidate: true,
    needsTaxGeneration: true,
    indirectTaxSummary: [],
  }),

  [CONST.VALIDATION_UI_FETCH_DATA_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.VALIDATION_UI_FETCH_DATA_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: null,
  }),

  [CONST.VALIDATION_UI_FETCH_DATA_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),

  [CONST.VALIDATION_UI_UNDO_REVERSAL_SUCCESS]: state => ({
    ...state,
    reversal: state.reversal.map((journal) => {
      if (!journal.reversed) {
        return {
          ...journal,
          selected: false,
          selectedPeriod: null,
          selectedMethod: null,
          disabled: false,
        };
      }
      return journal;
    }),
  }),

  [CONST.VALIDATION_UI_UPDATE_DATA_GRID]: (state, action) => ({
    ...state,
    dataGrid: action.data.dataGrid,
    dataGridChanges: [],
  }),

  [CONST.VALIDATION_UI_UPDATE_FILE_NAMES]: state => ({
    ...state,
    // TODO: Update the file names in data grid with action.data
  }),

  [CONST.VALIDATION_UI_REVALIDATE_COMPLETE]: state => ({
    ...state,
    needsRevalidate: false,
  }),

  [CONST.VALIDATION_UI_UPDATE_STATISTICS]: (state, action) => ({
    ...state,
    statistics: action.data.statistics,
  }),

  [CONST.VALIDATION_UI_UPDATE_PAGE]: (state, action) => {
    const {
      workbookName,
      statistics,
      reversal,
      dataGridColumns,
      dataGrid,
      originalModels,
    } = action.data;
    return {
      ...state,
      workbookName,
      statistics,
      reversal,
      dataGridColumns,
      dataGrid,
      originalModels,
      dataGridChanges: [],
    };
  },

  [CONST.VALIDATION_UI_POST_FILE_START]: state => ({
    ...state,
    isPosting: true,
    postingSummary: null,
  }),

  [CONST.VALIDATION_UI_POST_FILE_SUCCESS]: (state, action) => ({
    ...initialState,
    workbookName: action.payload.workbookName ? action.payload.workbookName : state.workbookName,
    isPosting: false,
    postingSummary: action.payload,
  }),

  [CONST.VALIDATION_UI_CLEAR_POSTING_SUMMARY]: state => ({
    ...state,
    isPosting: false,
    postingSummary: null,
  }),
  //

  [CONST.VALIDATION_UI_POST_FILE_FAILURE]: (state, action) => ({
    ...state,
    isPosting: false,
    postingSummary: null,
    error: action.error,
  }),

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_START]: state => ({
    ...state,
    loading: true,
    isSaving: true,
    successfulSaveDate: null,
  }),

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    isSaving: false,
    successfulSaveDate: action.payload,
  }),

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_FAILURE]: state => ({
    ...state,
    loading: false,
    isSaving: false,
  }),

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_RESET]: () => ({ ...initialState }),

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_NAME_START]: state => ({
    ...state,
    isSaving: true,
  }),

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_NAME_SUCCESS]: (state, action) => {
    const numBatchesInWorkbook = new Set(state.dataGrid.map(item => item.journal_batch_id)).size;
    return ({
      ...state,
      isSaving: false,
      workbookName: action.payload,
      dataGrid: state.dataGrid.map(item => ({
        ...item,
        journal_batch_name: numBatchesInWorkbook > 1 ? item.journal_batch_name : action.payload,
      })),
    });
  },

  [CONST.VALIDATION_UI_SAVE_WORKBOOK_NAME_FAILURE]: state => ({
    ...state,
    isSaving: false,
  }),

  [CONST.VALIDATION_UI_DOWNLOAD_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.VALIDATION_UI_DOWNLOAD_SUCCESS]: (state, action) => ({
    ...state,
    downloadWorkbookUrl: action.payload.workbookURL,
    loading: false,
  }),

  [CONST.VALIDATION_UI_DOWNLOAD_FAILURE]: state => ({
    ...state,
    downloadWorkbookUrl: null,
    loading: false,
  }),

  [CONST.VALIDATION_UI_DOWNLOAD_RESET]: state => ({
    ...state,
    downloadWorkbookUrl: null,
  }),

  [CONST.VALIDATION_UI_DELETE_ROW_START]: state => ({
    ...state,
    loading: true,
  }),

  [CONST.VALIDATION_UI_DELETE_ROW_SUCCESS]: state => ({
    ...state,
    loading: false,
    needsRevalidate: true,
    needsTaxGeneration: true,
    indirectTaxSummary: [],
  }),

  [CONST.VALIDATION_UI_DELETE_ROW_FAILURE]: state => ({
    ...state,
    loading: false,
  }),

  [CONST.VALIDATION_UI_ADD_ROW_START]: state => ({
    ...state,
    loading: true,
  }),

  [CONST.VALIDATION_UI_ADD_ROW_SUCCESS]: state => ({
    ...state,
    loading: false,
    needsRevalidate: true,
    needsTaxGeneration: true,
    indirectTaxSummary: [],
  }),

  [CONST.VALIDATION_UI_ADD_ROW_FAILURE]: state => ({
    ...state,
    loading: false,
  }),

  [CONST.VALIDATION_UI_GENERATE_TAX_LINES_START]: state => ({
    ...state,
    loading: true,
  }),

  [CONST.VALIDATION_UI_GENERATE_TAX_LINES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    needsTaxGeneration: false,
    indirectTaxSummary: action.payload.map((row, index) => ({ ...row, index: index + 1 })),
  }),

  [CONST.VALIDATION_UI_GENERATE_TAX_LINES_FAILURE]: state => ({
    ...state,
    loading: false,
  }),

  [CONST.VALIDATION_UI_DELETE_WORKBOOK_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.VALIDATION_UI_DELETE_WORKBOOK_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: null,
    workbookDeleted: true,
  }),

  [CONST.VALIDATION_UI_DELETE_WORKBOOK_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [CONST.VALIDATION_UI_DELETE_WORKBOOK_RESET]: () => ({ ...initialState }),

  [CONST.VALIDATION_UI_EXIT_WORKBOOK]: state => ({
    ...state,
    loading: false,
    error: null,
    exitedWorkbook: true,
  }),

  [CONST.VALIDATION_UI_EXIT_WORKBOOK_RESET]: () => ({ ...initialState }),
});
